import { AuthenticationGate } from '@goodlok/react-sdk'
import { Layout } from '../components/Layout'

export default function CommonPage() {
	return (
		<AuthenticationGate allowedRoles={['admin', 'shifts_worker']}>
			<Layout />
		</AuthenticationGate>
	)
}
